<template>
  <div class="shoppingcar">
      <!-- 头部 -->
      <van-nav-bar title="我的购物车"  left-text="返回" :right-text="isshow==true?'取消':'管理'" left-arrow @click-left="onClickLeft" @click-right="onClickRight"/>
     
      <!-- 内容为空 -->
      <div class="empty" v-if="this.show">
          <div class="empty-content">
            <img src="../assets/image/shopingcar-kong.png" alt="">
            <p>暂无商品</p>
          </div>
      </div> 
      <!-- 内容 -->
      <div class="content" v-if="this.list.length>0">
        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <div class="item" v-for="(item,index) in list" :key="item.id" >
            <div class="item-list" :class="index===list.length-1?'last':''">
              <van-checkbox :name="item" @click.stop="total" :class="isshow==true?'red':''"></van-checkbox>
              <div class="productDetail" @click="jump(item.productId)">
                <img :src="`${https}${item.productImgList[0].picPath}`" alt="">
                <div class="text">
                  <p class="title">{{item.productName}}</p>
                  <p class="suggest">这是一段简介</p>
                  <div class="price">
                    <span class="discount-price">￥<span>{{item.special==null?item.price:item.special}}</span></span>
                    <span v-if="item.special=='null'" class="normal-price">￥{{item.price}}</span>
                    <van-stepper button-size="19px" theme="round" v-model="item.productNum" integer :name="item.shopCartId" @plus="addnum(item.shopCartId)" @minus='cutnum(item.shopCartId)' @change="total"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-checkbox-group>
      
        <!-- 底部 -->
        <van-submit-bar v-if="isshow==false" :price="alltotal*100" decimal-length="2" :button-text="`结算(${result.length})`"  button-color="#4587ff"  @submit="onSubmit">
          <van-checkbox  v-model="checked" @click="checkAll" >全选</van-checkbox>
          <span class="text">不含运费</span>
        </van-submit-bar>

        <div class="del" v-if="isshow==true">
          <van-checkbox  v-model="checked" @click="checkAll" >全选</van-checkbox>
          <button @click="deleteShop">删除</button>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Stepper } from 'vant';
import { SubmitBar } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(SubmitBar);
Vue.use(Stepper);
import { findShopCart,shoppingcatNum,delshopingcat,payment } from "@api/servicemall.js";
 export default {
    data(){
      return{
        list:[],
        checked:'',
        result:[],
        https:'https://p.writemall.com/mallFile/product/',
        num:'',
        alltotal:null,
        newarr:[],  //价格数组
        isshow:false,
        delarr:[],   //删除使用的数组
        show:false,
        payarr:[],   //支付使用的数组
        oId:'',    //获得的支付id
        userId:'',
}
    },
     watch:{
       result:{
          handler:function(cur,old){
             if(cur.length == this.list.length){
               this.checked = true
             }else{
               this.checked = false
             }
          },
          deep:true
       }
    },
    methods:{
      //管理
      onClickRight(){
         this.isshow = !this.isshow
         if(this.isshow){
           this.result = [],
           this.checked = false
         }else{
           this.result = this.list
           this.checked = true
           this.total()
         }
      },
      //删除
      async deleteShop(){
        this.delarr = []
         this.result.map(item =>{
           let obj = {}
           obj.id = item.shopCartId
           this.delarr.push(obj)
         })
        const res = await delshopingcat(this.delarr)
        if(res.code===0){
          if(this.result === 0){
            this.getshoppingcar()
          }else{
            this.$router.go(0)
          } 
          
        }
      },
      //跳转详情 
      jump(id){
        this.$router.push({
          name:"details",
          query:{
            id:id
          }
        })
      },
      //合计
      total(){
         this.alltotal = null
         this.newarr = []
         this.result.map(item=>{
           if(item.special==null){
             this.newarr.push(item.price*item.productNum)
           }else{
             this.newarr.push(item.special*item.productNum)
           }
         })
         for(var i =0;i<this.newarr.length;i++){
           this.alltotal += this.newarr[i]
         }
      },

      //数量增加
      async addnum(id){
          event.stopPropagation();   //阻止冒泡
          const data = {
            id:id,
            increment:true
          }
          const result = await shoppingcatNum(data);
          if(result.code ===0){

          }else{
             this.$toast("系统故障，请联系管理员")  
          }
      },
        //数量减少
      async cutnum(id){
           event.stopPropagation();    //阻止冒泡
          const data = {
            id:id,
            increment:false
          }
          const result = await shoppingcatNum(data);
          if(result.code ===0){

          }else{
             this.$toast("系统故障，请联系管理员")  
          }
      },

      //全选
      checkAll() {
        if(this.checked){
           this.$refs.checkboxGroup.toggleAll(true);
           this.total()
        }else{
            this.$refs.checkboxGroup.toggleAll();
            this.total()
        }
      },
      //  返回
      onClickLeft() {
         this.$router.go(-1)
      },

      //支付
      async onSubmit(){ 
           let obj = this.result
           let ids = []
           obj.map((item)=>{
              ids.push(item.productId)
              delete item.description
              delete item.price
              delete item.productImgList
              delete item.productName
              delete item.shopCartId
              delete item.special
           })
           const res = await payment(obj)
           if(res.code === 0 ){
              sessionStorage.setItem("oid",res.data.oid)
              console.log(res.data.oid)
              this.$router.push({
                name:'buynow'
              })
           }
      },

      
      //请求购物车数据
      async getshoppingcar(){
        const result = await findShopCart();
        if(result.code === 0 ){
          if(result.data.length>0){
            this.list = result.data
            this.result = result.data
            this.checked = true
            this.total()
            this.show = false
          }else{
            this.show = true
          }
        
        }
      }
    },
    created(){ 
      this.getshoppingcar();
      
    }
  }
</script>

<style lang="scss" scoped>
.shoppingcar{
    font-family: PingFang SC;
    background: #f8f8f8;
    height: 100vh;
    ::v-deep .van-checkbox__icon .van-icon{
       border: 1px solid #4587FF;
    }
    .empty{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .empty-content{
        width: 162px;
        height: 135px;
        text-align: center;
        img{
          width: 162px;
          height: 118px;
        }
        p{
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #999999
        }
      }
    }
    .red{
       ::v-deep .van-checkbox__icon .van-icon{
          border: 1px solid #eb441e;
       }
       ::v-deep .van-checkbox__icon--checked .van-icon{
          background-color:#eb441e;
       }
    }
     ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
      ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-button--round{
      border-radius: 5px;
      width: 90px;
    }
    ::v-deep .van-submit-bar{
      bottom: 78px;
      box-shadow: 0px 5px 29px 0px rgba(6, 0, 1, 0.1);
      .text{
        font-size: 12px;
        font-weight: 400; 
        color: #999999;
        margin-left: 45px;
        transform: translateY(2px);
      }
    }
    .del{
          width: 100%;
          height: 50px;
          background: #fff;
          position: fixed;
          bottom: 78px;
          z-index: 999;
          box-shadow: 0px 5px 29px 0px rgba(6, 0, 1, 0.1);
        ::v-deep .van-checkbox{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }
        ::v-deep .van-checkbox__icon .van-icon{
          border: 1px solid #eb441e;
       }
       ::v-deep .van-checkbox__icon--checked .van-icon{
          background-color:#eb441e;
       }
        button{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right:18px;
          width: 90px;
          height: 40px;
          background: #fff;
          border: 1px solid #EB441E;
          font-size: 15px;
          font-weight: 500;
          color: #EB441E;
          border-radius: 5px;
        }
    }
    .content{
      margin-top: 10px;
      padding-bottom: 130px;
      .item{
        height: 113px;
        background: #fff;
        position: relative;
        .item-list{
          position: absolute;
          width: 335px;
          height: 113px;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          border-bottom: 1px solid #E3E3E3;
           ::v-deep .van-checkbox{
              display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            img{
              width: 74px;
              height: 85px;
              position: absolute;
              left: 40px;
              top: 50%;
              transform: translateY(-50%)
            }
            .text{
              height: 90px;
              width: 184px;
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 151px;
              transform: translateY(-50%);
               .title{
                  font-size: 15px;
                  font-weight: bold;
                  color: #2F3135;
                }
                .suggest{
                  font-size: 12px;
                  font-weight: 400;
                  color: #999999;
                }
              .price{
                margin-top: 10px;
                .discount-price{
                    font-size: 16px;
                    font-weight: bold;
                    color: #EB441E;
                    span{
                      font-size: 20px;
                    }
                }
                .normal-price{
                  font-size: 24px;
                  font-weight: 400;
                  text-decoration: line-through;
                  color: #999999;
                  margin-left: 4px;
                }
                ::v-deep .van-stepper{
                  float: right;
                  transform: translateY(10px);
                }
                   ::v-deep .van-stepper__minus{
                    color: #999999;
                    border: 1px solid #999999;
                  }
                  ::v-deep .van-stepper__plus{
                    background-color:#4587FF;
                  }
              }
            }
        }
        .last{
          border: none;
        }
      }
    }
}
</style>

